var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":`Welcome, ${_vm.currentUser.firstName}`},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('p',{staticClass:"grey--text"},[_vm._v("Here are the most important updates and tools to get you productive")])]},proxy:true}])},[_c('section',{staticClass:"consultant-dashboard-wrap"},[(false)?_c('v-row',{staticClass:"top-bar main-row",staticStyle:{"margin-bottom":"25px"},attrs:{"justify":"space-between","align":"center"}},[_c('div',{staticClass:"d-flex tabs-container"},_vm._l((_vm.ConsultantTabs),function(consultantTab,index){return _c('div',{key:`application_stage_${index}`,staticClass:"d-flex",class:{
            'consultant-tab-indicator-active': consultantTab.key === _vm.currentTab.key,
          },staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.handleTabClick(consultantTab.key)}}},[_c('div',{staticClass:"mb-2 consultant-tab-item center-child-items d-flex ma-5"},[_c('v-badge',{attrs:{"inline":"","content":"2","bordered":"","color":consultantTab.key === _vm.currentTab.key ? 'red' : 'white'}},[_c('span',{staticClass:"consultant-tab-item-name"},[_vm._v(" "+_vm._s(consultantTab.name))])])],1)])}),0),_c('div',{staticClass:"d-flex center-child-items"},[_c('div',{staticStyle:{"padding-top":"12px"}},[_c('v-badge',{attrs:{"overlap":"","content":"2"}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("la-bell")])],1)],1)])]):_vm._e(),_c('v-row',{staticClass:"main-row"},[_c('v-col',{staticClass:"py-0 stats-roles-wrap px-0",attrs:{"cols":"12","lg":"7"}},[(_vm.consultantProfile && _vm.consultantProfile.opportunities)?_c('div',{staticClass:"mb-5 open-opportunities-wrap"},[_c('v-card',{staticClass:"card d-flex align-center box-shadow",attrs:{"outlined":""}},[_c('div',{staticClass:"d-ib pa-y px-4"},[_c('v-icon',{attrs:{"color":"#2F80ED","x-large":""}},[_vm._v("la-rocket")])],1),_c('div',{staticClass:"d-ib"},[(
                  _vm.consultantProfile.opportunities.data &&
                  _vm.consultantProfile.opportunities.data.length
                )?_c('header-2',{staticClass:"pb-0"},[_vm._v("There "+_vm._s(_vm.consultantProfile.opportunities.data.length > 1 ? "are" : "is")+" "+_vm._s(_vm.consultantProfile.opportunities.data.length)+" open "+_vm._s(_vm.consultantProfile.opportunities.data.length > 1 ? "opportunities" : "opportunity")+" for you")]):_c('header-2',{staticClass:"pb-0"},[_vm._v("Discover available opportunities")]),_c('text-button',{attrs:{"color":"#2F80ED","to":{
                  name: _vm.$routes.Opportunities,
                }}},[_vm._v("Find your next opportunities "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("la-arrow-right")])],1)],1)])],1):_vm._e(),_c('OutstandingAppraisals'),_c('MissionUpdates',{attrs:{"loading":_vm.loading,"missions":_vm.consultantProfile && _vm.consultantProfile.missions && _vm.consultantProfile.missions.data}}),(_vm.currentTab.key === _vm.TabKeys.AFG_UPDATES)?_c('div',[_c('EarnParelteePoints'),_c('ConsultantsFeed'),_c('TalentOfTheWeek')],1):_vm._e()],1),_c('v-col',{staticClass:"pa-0 mt-5 mt-lg-0 pl-lg-7",attrs:{"cols":"12","lg":"5"}},[[(_vm.loading)?_c('v-card',{staticClass:"pa-4 mb-5",attrs:{"flat":""}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}})],1):_c('Availability',{attrs:{"consultantProfile":_vm.consultantProfile}})],(!_vm.can('core_team'))?_c('ConsultantHomeWallet',{attrs:{"loading":_vm.loading,"wallet":_vm.consultantProfile && _vm.consultantProfile.wallet}}):_vm._e(),_c('v-card',{staticClass:"rounded-xl",staticStyle:{"background":"inherit"},attrs:{"flat":""}},[_c('TasksWidget',{attrs:{"widget":true}})],1)],2)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }