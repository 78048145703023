
import { Vue, Component } from "vue-property-decorator"
import ConsultantFeedPost from "@/components/consultant/ConsultantFeedPost.vue"
import ConsultantFeedVideoPost from "@/components/consultant/ConsultantFeedVideoPost.vue"

import moment from "moment"

interface IReactions {
  likes: number
  comments: number
  shares: number
}

enum FeedItemTypes {
  POST = "POST",
  VIDEO = "VIDEO",
}

interface IUser {
  id: string
  firstName: string
  lastName: string
  profilePic: string
}

export interface IFeedItem {
  id: string
  user: IUser
  title?: string
  subheading?: string
  description: string
  role: string
  link?: string
  kind: FeedItemTypes
  reactions: IReactions
  createdAt: string
}
@Component({
  components: {
    ConsultantFeedPost,
    ConsultantFeedVideoPost,
  },
})
export default class ConsultantsFeed extends Vue {
  feedItems: IFeedItem[] = [
    {
      id: "p1",
      user: {
        id: "u1",
        firstName: "Ofentse",
        lastName: "Xulu",
        profilePic: "clients/Daniel_Khosa.png",
      },
      createdAt: "05/07/22",
      kind: FeedItemTypes.POST,
      title: "Top lessons I learnt being at Peralta for the last two years.",
      description:
        "Many people learn some of their greatest life lessons in the workplace. Being open and willing to learn these lessons just keep going on.",
      role: "Mission Partner",
      reactions: {
        likes: 10,
        comments: 5,
        shares: 10,
      },
    },
    {
      id: "p2",
      user: {
        id: "u2",
        firstName: "Grace",
        lastName: "Xulu",
        profilePic: "clients/Grace_Xulu.png",
      },
      createdAt: "05/05/22",
      kind: FeedItemTypes.VIDEO,
      link: "/videoplaceholder.png",
      title:
        "Peer Evaluation. These may be the trickiest of all appraisal programs, because conducting peer evaluations requires extensive training for employees.",
      subheading: "Appraisal Program",
      description:
        "Common misconceptions about performance appraisal software. For most organizations, implementing a brand new performance management system can ...",
      role: "Mission Partner",
      reactions: {
        likes: 2,
        comments: 3,
        shares: 3,
      },
    },
  ]

  getPostCreatedAt(dateEntry: string) {
    return moment(dateEntry).fromNow()
  }
}
