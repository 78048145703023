
import { Component, Vue } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import { MyPerformanceAppraisalsQuery, MissionPerformanceAppraisal } from "@/gql"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import { ApolloError } from "@apollo/client/core"
import { SmartQuery } from "vue-apollo/types/vue-apollo"
import { ApolloQueryResult, NetworkStatus } from "@apollo/client/core"
import SectionCard from "@/views/missions/details/SectionCard.vue"
import UserAvatar from "@/components/profile/UserAvatar.vue"

@Component({
  components: {
    FeedBackMessage,
    AppPage,
    SectionCard,
    UserAvatar,
  },
})
export default class OutstandingAppraisals extends Vue {
  readonly myPerformanceAppraisalsQuery = MyPerformanceAppraisalsQuery
  fullNameCharactersLimit = 15

  loading = false
  offset = 0
  maxItems = 2

  activeAppraisal: MissionPerformanceAppraisal | null = null

  pendingCount = 0
  totalCount = 0
  loadingError = false
  networkError = false

  get performanceAppraisalsQueryVars() {
    return {
      filter: {
        state: "PENDING",
      },
      sort: {},
      page: 1,
      per: 6,
    }
  }

  onResult(result: ApolloQueryResult<any>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.loading = false
      this.loadingError = false

      if (result.data && result.data.myPerformanceAppraisals) {
        this.pendingCount = result.data.myPerformanceAppraisals.data.length
        this.totalCount = result.data.myPerformanceAppraisals.pagination.totalSize
      }
    } else if (result.networkStatus === NetworkStatus.error) {
      this.loadingError = true
    }
  }

  next() {
    this.offset += this.maxItems
  }

  previous() {
    this.offset -= this.maxItems
  }

  refetchQueries() {
    return [
      {
        query: this.myPerformanceAppraisalsQuery,
        variables: { ...this.performanceAppraisalsQueryVars },
      },
    ]
  }
  onError(error: ApolloError) {
    if (error.graphQLErrors) {
      this.loadingError = true
    } else if (error.networkError) {
      this.networkError = true
    }
  }

  async handleLoadingErrorButtonClick(query: SmartQuery<any>) {
    this.$set(this, "loading", true)
    await query.refetch()
  }
}
