
import UserAvatar from "@/components/profile/UserAvatar.vue"
import { Component, Vue, Prop } from "vue-property-decorator"
import { Conversation } from "@/gql"
import { useTimeAgo } from "@/hooks/useTimeAgo"

@Component({
  components: {
    UserAvatar,
  },
})
export default class UnreadMessages extends Vue {
  @Prop({ required: true }) conversations!: Conversation[]

  timeAgo(date: string) {
    return useTimeAgo(date)
  }
}
