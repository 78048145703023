
import { Vue, Component, Prop } from "vue-property-decorator"
import moment from "moment"
import { IFeedItem } from "./ConsultantsFeed.vue"

@Component
export default class ConsultantsFeedVideoPost extends Vue {
  @Prop({ required: true })
  feedItem!: IFeedItem

  handleWatchNow() {
    console.log("WATCH NOW")
  }
  getPostCreatedAt(dateEntry: string) {
    return moment(dateEntry).fromNow()
  }
}
