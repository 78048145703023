
import { Component, Vue } from "vue-property-decorator"
import { ConsultantProfileFragmentFragment, ConsultantProfileQuery } from "@/gql"
import Availability from "@/components/consultant/Availability.vue"
import MissionUpdates from "@/components/consultant/MissionUpdates.vue"
import ConsultantHomeWallet from "@/components/consultant/ConsultantHomeWallet.vue"
import OutstandingAppraisals from "@/components/consultant/OutstandingAppraisals.vue"
import EarnParelteePoints from "@/components/consultant/EarnParelteePoints.vue"
import TalentOfTheWeek from "@/components/consultant/TalentOfTheWeek.vue"
import ConsultantsFeed from "@/components/consultant/ConsultantsFeed.vue"
import { NetworkStatus } from "@apollo/client/core"
import TasksWidget from "@/components/tasks/TasksWidget.vue"
import AppPage from "@/components/layout/AppPage.vue"

enum TabKey {
  MY_UPDATES,
  AFG_UPDATES,
}

interface ITab {
  key: TabKey
  name: string
  heading: string
}

@Component({
  components: {
    TasksWidget,
    Availability,
    OutstandingAppraisals,
    MissionUpdates,
    ConsultantHomeWallet,
    EarnParelteePoints,
    TalentOfTheWeek,
    ConsultantsFeed,
    AppPage,
  },
  apollo: {
    consultantProfile: {
      query: ConsultantProfileQuery,
      result(result) {
        if (
          result.networkStatus === NetworkStatus.ready ||
          result.networkStatus === NetworkStatus.error
        ) {
          this.loading = false
        }
      },
      error() {
        this.errorLoading = true
      },
    },
  },
})
export default class HomePage extends Vue {
  loading = true
  currentTab: ITab = {} as ITab
  TabKeys = TabKey
  consultantProfile!: ConsultantProfileFragmentFragment
  errorLoading = false
  ConsultantTabs: ITab[] = [
    {
      key: TabKey.MY_UPDATES,
      name: "My Updates",
      heading: "My Updates",
    },
    {
      key: TabKey.AFG_UPDATES,
      name: "AFG Updates",
      heading: "AFG Updates",
    },
  ]

  handleTabClick(key: TabKey) {
    this.currentTab = this.ConsultantTabs.find((tab) => tab.key === key)!
  }

  mounted() {
    this.currentTab = this.ConsultantTabs[0]
  }
}
