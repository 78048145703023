
import { Vue, Component, Prop } from "vue-property-decorator"

interface IReactions {
  likes: number
  comments: number
  shares: number
}
interface ITalent {
  firstName: string
  lastName: string
  profilePic: string
  description: string
  role: string
  reactions: IReactions
}
@Component
export default class TalentOfTheWeek extends Vue {
  @Prop({
    default: "Talent Of The Week",
  })
  readonly label?: string

  descriptionCharactersLimit = 60
  displayFullDescription = false

  talent: ITalent = {
    firstName: "James",
    lastName: "Sambo",
    profilePic: "clients/James_Sambo.png",
    description:
      "James has demonstrated the highest level of specialization, deep knowledge of the field. Excellent skills.",
    role: "Mission Partner",
    reactions: {
      likes: 10,
      comments: 5,
      shares: 10,
    },
  }

  toggleDisplayFullDescription() {
    this.displayFullDescription = !this.displayFullDescription
  }
}
