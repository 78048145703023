
import { Component, Vue, Prop } from "vue-property-decorator"
import Icon from "@/components/widgets/icons/Icon.vue"
import { Task } from "@/gql"
import { useUrl } from "@/hooks/useUrl"

@Component({ components: { Icon } })
export default class InlineTaskCard extends Vue {
  @Prop() readonly task!: Task

  getActionLink(to: { name: string; params: { id: number } }): string {
    const { href } = useUrl(to.name, { id: to.params.id })
    return href
  }

  get iconName() {
    return this.task.metadata.action.label?.split(" ")[0].toLowerCase()
  }

  icons: Record<string, string> = {
    view: "eye",
    complete: "checklist-minimalistic",
    sign: "pen-2",
    approve: "user-check",
  }
}
