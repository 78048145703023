
import { ConsultantInvoice } from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import ConsultantWallet from "./ConsultantWallet.vue"

@Component({
  components: {
    ConsultantWallet,
  },
})
export default class ConsultantHomeWallet extends Vue {
  @Prop() readonly wallet!: {
    totalOutstanding: string
    totalEarned: string
  }

  @Prop() readonly nextPayment?: ConsultantInvoice
  @Prop({ default: "To unlock your next payment" }) readonly label?: string
  @Prop({ default: false }) readonly loading!: boolean
}
