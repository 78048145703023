var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ApolloQuery',{attrs:{"query":_vm.myPerformanceAppraisalsQuery,"variables":_vm.performanceAppraisalsQueryVars,"notifyOnNetworkStatusChange":""},on:{"result":_vm.onResult,"error":_vm.onError},scopedSlots:_vm._u([{key:"default",fn:function({ result: { loading, data }, query, isLoading }){return [(
        (!loading || !isLoading) &&
        data &&
        data.myPerformanceAppraisals &&
        data.myPerformanceAppraisals.data.length
      )?_c('section-card',{staticClass:"mb-5 pb-2 pb-6",staticStyle:{"overflow-x":"auto"},attrs:{"title":"Pending Appraisals","flat":""}},[(loading || isLoading)?[_c('v-row',{staticClass:"px-2"},[_c('v-col',{attrs:{"sm":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}})],1)],1),_c('v-col',{attrs:{"sm":"4"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line"}})],1)],1)],1)]:(data && data.myPerformanceAppraisals.data.length)?[_c('div',{staticClass:"d-flex align-center pr-2",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"control-buttons"},[(_vm.offset !== 0)?_c('v-btn',{staticClass:"mr-2 carousel-button",attrs:{"icon":""},on:{"click":_vm.previous}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("la-angle-left")])],1):_vm._e()],1),_c('div',{staticClass:"appraisal-cards-wrap d-flex flex-grow-1"},_vm._l((data.myPerformanceAppraisals.data.slice(
                _vm.offset,
                _vm.offset + _vm.maxItems
              )),function(consultantAppraisal){return _c('v-card',{key:`consultantAppraisal_${consultantAppraisal.id}`,staticClass:"pa-3 d-flex align-center mr-3",attrs:{"outlined":""}},[_c('user-avatar',{attrs:{"user":consultantAppraisal.appraisee.user,"size":50}}),_c('div',{staticClass:"appraisee-name ml-4"},[_c('div',[_c('b',{staticClass:"truncate"},[_vm._v(" "+_vm._s(consultantAppraisal.appraisee.name))])]),_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(consultantAppraisal.appraiseeRole.name)+" ")])]),_c('SecondaryButton',{staticClass:"ml-3",attrs:{"to":{
                  name: _vm.$routes.SurveyDetail,
                  params: {
                    id: consultantAppraisal.surveyAssignment.id.toString(),
                  },
                }}},[_vm._v(" View ")])],1)}),1),_c('div',{staticClass:"control-buttons"},[(_vm.offset < data.myPerformanceAppraisals.data.length - _vm.maxItems)?_c('v-btn',{staticClass:"carousel-button",attrs:{"icon":""},on:{"click":_vm.next}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("la-angle-right")])],1):_vm._e()],1)])]:(_vm.loadingError)?_c('FeedBackMessage',{attrs:{"resource":"appraisals","preset":"loadingError","buttonOnClick":() => _vm.handleLoadingErrorButtonClick(query)}}):(_vm.networkError)?_c('FeedBackMessage',{attrs:{"resource":"appraisals","preset":"networkError"}}):_c('FeedBackMessage',{attrs:{"resource":"appraisals","preset":"emptyState","customMessage":" "}})],2):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }