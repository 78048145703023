
import { Vue, Component, Prop } from "vue-property-decorator"
import moment from "moment"
import { IFeedItem } from "./ConsultantsFeed.vue"

@Component
export default class ConsultantsFeedPost extends Vue {
  descriptionCharactersLimit = 118
  displayFullDescription = false

  @Prop({ required: true })
  feedItem!: IFeedItem

  toggleDisplayFullDescription() {
    this.displayFullDescription = !this.displayFullDescription
  }
  getPostCreatedAt(dateEntry: string) {
    return moment(dateEntry).fromNow()
  }
}
