
import { Component, Vue } from "vue-property-decorator"
import AppPage from "@/components/layout/AppPage.vue"
import { MyPerformanceAppraisalsQuery, MissionPerformanceAppraisal } from "@/gql"
import FeedBackMessage from "@/components/content/FeedBackMessage.vue"
import { ApolloError } from "@apollo/client/core"
import { SmartQuery } from "vue-apollo/types/vue-apollo"
import { ApolloQueryResult, NetworkStatus } from "@apollo/client/core"

enum PointsTipsKey {
  Referrals,
  HelpWithInterviews,
  TrainingMaterials,
  ExtraMaterials,
}
interface IPointsTip {
  id: number
  title: string
  icon: string
}

@Component({
  components: {
    FeedBackMessage,
    AppPage,
  },
})
export default class EarnParelteePoints extends Vue {
  readonly myPerformanceAppraisalsQuery = MyPerformanceAppraisalsQuery
  paginate = { page: 1, per: 30 }
  loading = false
  offset = 0
  maxItems = 3

  pointsTips: IPointsTip[] = [
    {
      id: PointsTipsKey.Referrals,
      title: "Refer new consultants to join our team",
      icon: "la-gift",
    },
    {
      id: PointsTipsKey.HelpWithInterviews,
      title: "Help interview and assess prospective consultant",
      icon: "la-user-cog",
    },
    {
      id: PointsTipsKey.TrainingMaterials,
      title: "Develop training materials",
      icon: "la-edit",
    },
    {
      id: PointsTipsKey.ExtraMaterials,
      title: "Develop training extra materials",
      icon: "la-edit",
    },
  ]

  showReviewDialog = false
  activeAppraisal: MissionPerformanceAppraisal | null = null

  pendingCount = 0
  totalCount = 0
  loadingError = false
  networkError = false

  get performanceAppraisalsQueryVars() {
    return {
      filter: {
        state: "PENDING",
      },
      sort: {},
      page: 1,
      per: 6,
    }
  }

  onAppraisalAction(action: string, appraisal: MissionPerformanceAppraisal) {
    this.activeAppraisal = appraisal

    if (action === "review" || action === "open") {
      this.$router.push({
        name: this.$routes.SurveyDetail,
        params: { id: appraisal.surveyAssignment.id.toString() },
      })
    }
  }

  onResult(result: ApolloQueryResult<any>) {
    if (result.networkStatus === NetworkStatus.ready) {
      this.loading = false
      this.loadingError = false

      if (result.data && result.data.myPerformanceAppraisals) {
        this.pendingCount = result.data.myPerformanceAppraisals.data.length
        this.totalCount = result.data.myPerformanceAppraisals.pagination.totalSize
      }
    } else if (result.networkStatus === NetworkStatus.error) {
      this.loadingError = true
    }
  }

  next() {
    this.offset += this.maxItems
  }

  previous() {
    this.offset -= this.maxItems
  }

  refetchQueries() {
    return [
      {
        query: this.myPerformanceAppraisalsQuery,
        variables: { ...this.performanceAppraisalsQueryVars },
      },
    ]
  }
  onError(error: ApolloError) {
    if (error.graphQLErrors) {
      this.loadingError = true
    } else if (error.networkError) {
      this.networkError = true
    }
  }

  async handleLoadingErrorButtonClick(query: SmartQuery<any>) {
    this.$set(this, "loading", true)
    await query.refetch()
  }
}
